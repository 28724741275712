export const environment = {
    production: false,
    CONSTANTS: {
        EXOCET_URL: 'https://exocet.billpocket.dev',
        SPUTNIK_SERVICE_URL: 'https://sputnik.billpocket.dev',
        WEB_PANEL_URL: 'https://test.dashboard.billpocket.com',
        RECAPTCHA_KEY: '6LeF4_8ZAAAAAOHKORu97bvcW7b5TEzlyFFiFe68',
        RIGHTS_URL: 'https://ayuda.billpocket.com/support/solutions/articles/156346-t-rminos-y-condiciones-del-servicio',
        PRIVACY_URL: 'https://ayuda.billpocket.com/support/solutions/articles/156382-aviso-de-privacidad',
        APP_STORE_URL: 'https://itunes.apple.com/us/app/billpocket/id625596360',
        PLAY_STORE_URL: 'https://play.google.com/store/apps/details?id=com.billpocket.billpocket',
        SUPPORT_EMAIL: 'soporte@billpocket.com',
        SUPPORT_PHONE: '8008905588',
    }
};
