import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { OnboardingCanLoadGuard } from 'src/app/shared/guards/onboarding-can-load.guard';

const routes: Routes = [
    {
        path: 'sign-in/:distributor',
        loadChildren: () => import('./sign-in/sign-in.module').then(m => m.SignInModule),
        // canActivate: [AuthGuard]
    },
    {
        path: 'sign-in',
        loadChildren: () => import('./sign-in/sign-in.module').then(m => m.SignInModule),
    },
    {
        path: 'onboarding',
        loadChildren: () => import('./onboarding/onboarding.module').then(m => m.OnboardingModule),
        canLoad: [OnboardingCanLoadGuard]
    },
    { path: '**', pathMatch: 'full', redirectTo: '/sign-in' }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
