import { BREAKPOINT } from '@angular/flex-layout';


export const CUSTOM_BREAKPOINTS = [
    { alias: 'xs', suffix: 'Xs', mediaQuery: 'screen and (max-width: 575px)', overlapping: false },
    { alias: 'sm', suffix: 'Sm', mediaQuery: 'screen and (min-width: 576px) and (max-width: 767px)', overlapping: false },
    { alias: 'md', suffix: 'Md', mediaQuery: 'screen and (min-width: 768px) and (max-width: 991px)', overlapping: false },
    { alias: 'lg', suffix: 'Lg', mediaQuery: 'screen and (min-width: 992px) and (max-width: 1199px)', overlapping: false },
    { alias: 'xl', suffix: 'Xl', mediaQuery: 'screen and (min-width: 1200px) and (max-width: 5000px)', overlapping: false },

    { alias: 'lt-sm', suffix: 'LtSm', mediaQuery: 'screen and (max-width: 575px)', overlapping: false },
    { alias: 'lt-md', suffix: 'LtMd', mediaQuery: 'screen and (max-width: 767px)', overlapping: false },
    { alias: 'lt-lg', suffix: 'LtLg', mediaQuery: 'screen and (max-width: 991px)', overlapping: false },
    { alias: 'lt-xl', suffix: 'LtXl', mediaQuery: 'screen and (max-width: 1199px)', overlapping: false },

    { alias: 'gt-xs', suffix: 'GtXs', mediaQuery: 'screen and (min-width: 576px)', overlapping: false },
    { alias: 'gt-sm', suffix: 'GtSm', mediaQuery: 'screen and (min-width: 768px)', overlapping: false },
    { alias: 'gt-md', suffix: 'GtMd', mediaQuery: 'screen and (min-width: 992px)', overlapping: false },
    { alias: 'gt-lg', suffix: 'GtLg', mediaQuery: 'screen and (min-width: 1200px)', overlapping: false },
    { alias: 'gt-xl', suffix: 'GtXl', mediaQuery: 'screen and (min-width: 2251px)', overlapping: false },
];

export const CustomBreakpointsProvider = {
    provide: BREAKPOINT,
    useValue: CUSTOM_BREAKPOINTS,
    multi: true
};
