import { Injectable } from '@angular/core';
import { CanLoad, Route, Router, UrlSegment } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class OnboardingCanLoadGuard implements CanLoad {
    constructor(
        private router: Router,
    ) { }

    async canLoad(route: Route, url: UrlSegment[]): Promise<boolean> {
        const onboardingId = url[1];
        if (!onboardingId) { this.router.navigateByUrl('/sign-in'); }
        return true;
    }

}
