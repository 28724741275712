import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { BpSnackbarService } from '../services/bp-snack-bar.service';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
    constructor(public snackbar: BpSnackbarService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request)
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    if (error.status === 0) {
                        this.snackbar.error('Se ha perdido la conexión a internet. Por favor recargue la pagina');
                    }
                    return throwError(error);
                })
            );
    }
}
