// tslint:disable: ban-types
import { Injectable, NgZone } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';

@Injectable({
    providedIn: 'any'
})
export class BpSnackbarService {
    constructor(private snackBar: MatSnackBar, private zone: NgZone) { }

    public error(message: string, action?: string, onAction?: Function, aditionalConfigs?: MatSnackBarConfig): void {
        const config = { ...aditionalConfigs, panelClass: 'bg-danger' };
        this.open(message, config, action, onAction);
    }

    public warning(message: string, action?: string, onAction?: Function, aditionalConfigs?: MatSnackBarConfig): void {
        const config = { ...aditionalConfigs, panelClass: 'bg-warning' };
        this.open(message, config, action, onAction);
    }

    public success(message: string, action?: string, onAction?: Function, aditionalConfigs?: MatSnackBarConfig): void {
        const config: MatSnackBarConfig = { ...aditionalConfigs, panelClass: 'bg-success' };
        this.open(message, config, action, onAction);
    }

    private open(message: string, aditionalConfigs: MatSnackBarConfig, action: string, onAction?: Function): void {
        this.zone.run(() => {
            const defaultConfig: MatSnackBarConfig = {
                verticalPosition: 'top',
                horizontalPosition: 'right',
                duration: 7000,
                panelClass: '',
                ...aditionalConfigs
            };
            const snackBar = this.snackBar.open(message, action, defaultConfig);
            const subcription = snackBar.onAction().subscribe(() => {
                onAction ? onAction() : snackBar.dismiss();
                subcription.unsubscribe();
            });
        });
    }
}
