// tslint:disable: variable-name
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class LoaderService {
    private _showing = false;
    private timer: NodeJS.Timeout;

    get showing(): boolean {
        return this._showing;
    }

    private setShowing(value: boolean): void {
        if (this.timer) {
            clearTimeout(this.timer);
            this.timer = null;
        }
        this.timer = setTimeout(() => this._showing = value, 100);
    }

    public show(): void {
        this.setShowing(true);
    }

    public hide(): void {
        this.setShowing(false);
    }

}
