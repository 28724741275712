import { AfterViewInit, Component } from '@angular/core';
import { environment } from 'src/environments/environment';
import { LoaderService } from './shared/services/loader.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit {

    constructor(public loader: LoaderService) { }

    ngAfterViewInit(): void {
        const recaptchaScript = document.createElement('script');
        const GTMScript = document.createElement('script');
        const GTMNoScript = document.createElement('noscript');
        recaptchaScript.async = true;
        recaptchaScript.defer = true;
        GTMScript.async = true;
        GTMScript.defer = true;
        GTMScript.type = 'text/javascript';
        GTMScript.text = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-PQLMWZD');`;
        GTMNoScript.prepend(`<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-PQLMWZD"
        height="0" width="0" style="display:none;visibility:hidden"></iframe>`);
        recaptchaScript.setAttribute('src', `https://www.google.com/recaptcha/api.js?render=${environment.CONSTANTS.RECAPTCHA_KEY}`);
        document.head.appendChild(GTMScript);
        document.body.appendChild(GTMNoScript);
        document.head.appendChild(recaptchaScript);
    }
}
